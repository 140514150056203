import React, {useCallback} from 'react';
import { Icon, LegacyStack, Text } from "@shopify/polaris";
import { ExternalIcon } from "@shopify/polaris-icons";
import PlusLogoGreen from '../../../../../images/PlusLogoGreen.png';
import './checkoutExtensionGuide.css';

const CustomizationTab = (props) => {
  const {title, content} = props;

  const onClickHandler = useCallback(() => {
    window.open(`https://apps.shopify.com/customcheckoutplus`, '_blank')
  }, []);

  return (
    <div className='Guide-content__item' onClick={() => onClickHandler()}>
      <LegacyStack distribution='equalSpacing'>
        <LegacyStack vertical spacing='extraTight'>
          <Text variant="bodyMd" as="span">
            {title}
          </Text>
          <Text  variant="bodyMd" as="span" tone="subdued">
            {content}
          </Text>
        </LegacyStack>
        <LegacyStack alignment='center' spacing='tight'>
          <Icon source={ExternalIcon} tone='base'/>
          <img src={PlusLogoGreen} alt={'PlusLogoGreen'} className='Guide__icon'/>
        </LegacyStack>
      </LegacyStack>
    </div>
  );
};

export default CustomizationTab;